import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout/Layout"
import Article from "../../../components/Article/Article"
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"
import i18n from "i18next"

const SeoArticle = ({ data }) => {
  useEffect(() => {
    i18n.changeLanguage("pl")
  }, [])
  return (
    <Layout
      seo={{
        title: "Stosunek treści strony do kodu źródłowego. Czy ma wpływ na SEO?",
        description:
          "Dowiedz się, czym jest stosunek treści strony internetowej do jej kodu źródłowego, ile powinien wynosić i sprawdź, czy ma to znaczenie w kontekście SEO.",
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/baza-wiedzy/artykuly/",
                name: "Baza wiedzy",
              },
            },
            {
              "@type": "ListItem",
              position: 3,
              item: {
                "@id": "/baza-wiedzy/artykul/jaki-powinien-byc-stosunek-tresci-do-kodu-zrodloweg-strony/",
                name: "Stosunek treści strony do kodu źródłowego. Czy ma wpływ na SEO?",
              },
            },
          ],
        },
      }}
    >
      <Breadcrumbs
        path={[
          { url: "/", text: "Główna" },
          {
            url: "/baza-wiedzy/artykuly/",
            text: "Baza wiedzy / Artykuły",
          },
          {
            url: "/baza-wiedzy/artykul/jaki-powinien-byc-stosunek-tresci-do-kodu-zrodloweg-strony",
            text: "Jaki powinien być stosunek treści do kodu źródłowego strony? ",
          },
        ]}
      />
      <Article
        img={
          <img
            src={data.file.childImageSharp.fluid.src}
            alt={"Jaki powinien być stosunek treści do kodu źródłowego strony? "}
          />
        }
      >
        <h1>Jaki powinien być stosunek treści do kodu źródłowego strony?</h1>
        <p>
          <strong>
            Treść strony internetowej, czyli tzw. content jest jednym z najważniejszych elementów, wpływających na SEO.
            Zapewne nie raz słyszałeś hasło: content is king. Dużo w nim prawdy, ponieważ przygotowanie dobrych tekstów
            zgodnych z zasadami SEO przyczynia się do wysokich pozycji w wynikach wyszukiwania. A co ze stosunkiem
            treści do kodu źródłowego strony w kontekście SEO? M.in. o tym piszemy poniżej.
          </strong>
        </p>
        <h2>Czym są: treść strony i kod źródłowy HTML?</h2>
        <p>Na początku warto pokrótce objaśnić dwa omawiane pojęcia, czyli treść strony (content) oraz kod źródłowy.</p>
        <ul>
          <li>
            <p>
              <strong>Content </strong>to po angielsku „zawartość”. W kontekście stron słowo to oznacza wszystkie teksty
              i zdjęcia, które udostępnia dany adres WWW. Treści zawierające słowa kluczowe, dobre merytorycznie oraz
              poprawne stylistycznie i językowo mają znaczący wpływ na popularność strony. To logiczne, że wówczas dany
              adres cieszy się renomą wśród użytkowników. A poza tym – co nie mniej istotne – jest także dobrze oceniany
              przez algorytmy wyszukiwarek.
            </p>
          </li>
          <li>
            <p>
              <strong>Kod źródłowy strony</strong> internetowej to jej zapis w języku HTML, który umożliwia wyświetlanie
              się treści: tekstów, akapitów, linków, grafik itd. Użytkownik Internetu nie widzi kodu, bo przeglądarki
              internetowe automatycznie przekształcają go na obraz strony WWW w znanej nam formie.
            </p>
          </li>
        </ul>
        <h2>Ile powinien wynosić stosunek treści do kodu?</h2>
        <p>
          Wskaźnik do mierzenia stosunku treści do kodu źródłowego witryny nazywa się THR, czyli{" "}
          <strong>Text to HTML Ratio</strong>. Opisuje on relację pomiędzy ilością tekstu wyświetlanego na stronie
          internetowej a ilością kodu źródłowego danej strony WWW. Wynik THR przedstawiany jest w procentach.
        </p>
        <p>
          Text to HTML Ratio pokazuje także liczbę słów na stronie, która de facto nie ma znaczącego wpływu na SEO. Od
          ilości znacznie ważniejsza jest jakość przedstawianych treści.
        </p>
        <p>
          Natomiast jeśli chodzi o wskaźniki, uważa się, że wysoki współczynnik kodu może być oznaką powolnego działania
          witryny.
        </p>
        <p>
          Według specjalistów optymalny <strong>stosunek – treść-kod oscyluje pomiędzy 25 a 70%</strong>. Jednak są to
          liczby orientacyjne, bo w niektórych przypadkach wynik może odbiegać od idealnych wartości, a mimo to strona
          będzie cieszyć się zaufaniem wyszukiwarki.
        </p>
        <h2>Jaki jest stosunek pomiędzy THR a SEO?</h2>
        <p>
          Jak pokazaliśmy wyżej, THR może sygnalizować pewne kwestie, takie jak np. niewydajne działanie strony WWW, czy
          podejrzana zawartość witryny (spam), co bez wątpienia ma wpływ na SEO i obniża ocenę strony.
        </p>
        <p>
          Natomiast warto podkreślić, że współczynnik tekstu do kodu nie oddziałuje bezpośrednio na pozycję strony w
          wynikach wyszukiwania.
        </p>
        <h2>Analiza kodu źródłowego strony WWW</h2>
        <p>
          Aby zoptymalizować stosunek treści do kodu, należy najpierw dokładnie przeanalizować kod strony. Można to
          zrobić za pomocą narzędzi dostępnych online, takich jak <strong>Google PageSpeed Insights</strong> czy{" "}
          <strong>GTmetrix</strong>. Te narzędzia pomogą zidentyfikować problemy związane z kodem, takie jak nadmiarowe
          linie kodu, nieużywane skrypty czy nieoptymalne obrazy.
        </p>
        <h2>Rodzaje kodu i ich wpływ na stosunek treści do kodu</h2>
        <p>Istnieją różne rodzaje kodów: HTML, CSS czy JavaScript. Każdy z nich pełni inną funkcję.</p>
        <p>
          HTML odpowiada za strukturę strony, CSS za jej wygląd, a JavaScript za interakcje z użytkownikiem. Im większy
          jest udział kodu w stosunku do treści, tym trudniej wyszukiwarkom ocenić wartość informacyjną strony, co może
          wpłynąć na jej pozycję w wynikach wyszukiwania.
        </p>
        <h3>Optymalizacja kodu HTML</h3>
        <p>
          Optymalizacja kodu HTML polega na usunięciu zbędnych elementów, takich jak puste znaczniki, nadmiarowe spacje
          czy komentarze.
        </p>
        <p>
          Warto również zastanowić się nad użyciem semantycznych znaczników HTML5, które ułatwiają wyszukiwarkom
          zrozumienie struktury strony. Ponadto warto unikać zagnieżdżeń oraz stosować odpowiednie sformatowanie kodu,
          co ułatwi jego analizę i ewentualne modyfikacje.
        </p>
        <h3>Optymalizacja kodu CSS</h3>
        <p>
          Podobnie jak w przypadku HTML, optymalizacja kodu CSS{" "}
          <strong>polega na usunięciu zbędnych elementów oraz minimalizacji ilości linii kodu</strong>. Można to
          osiągnąć poprzez grupowanie selektorów, wykorzystanie skrótów czy eliminowanie nieużywanych reguł. Warto
          rozważyć również eksternalizację arkuszy stylów (umieszczenie ich w osobnych plikach), co przyspieszy
          ładowanie strony.
        </p>
        <h3>Optymalizacja kodu JavaScript</h3>
        <p>
          W przypadku JavaScript, kluczowe jest zminimalizowanie ilości kodu oraz jego optymalizacja pod kątem
          wydajności.
        </p>
        <p>
          Należy <strong>unikać nadmiernego stosowania skryptów</strong>, zwłaszcza tych, które nie są niezbędne do
          funkcjonowania strony. Warto również korzystać z technik takich jak minifikacja czy kompresja plików, które
          zmniejszają rozmiar skryptów i przyspieszają ładowanie strony.
        </p>
        <h2>Sprawdzenie kodu źródłowego –podsumowanie</h2>
        <p>
          Optymalizacja kodu źródłowego jest istotnym elementem strategii SEO. Poprzez analizę i optymalizację
          poszczególnych rodzajów kodu, można osiągnąć lepsze wyniki w pozycjonowaniu strony WWW i przyciągnąć większą
          liczbę potencjalnych klientów.
        </p>
        <p>
          <br className="ProseMirror-trailingBreak" />
        </p>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "img/full/tresc-a-kod-zrodlowy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
  }
`

export default SeoArticle
